import { template as template_64779b72ffe64a1894e62ff26b2ae17f } from "@ember/template-compiler";
import styles from './video-player.css';
import type { TOC } from '@ember/component/template-only';
const parseUrl = (url: string)=>{
    const embedUrl = new URL('https://www.youtube.com');
    const u = new URL(url);
    const id = u.searchParams.has('v') ? u.searchParams.get('v') : u.pathname;
    embedUrl.pathname = `embed/${id}`;
    if (u.searchParams.has('t')) {
        embedUrl.searchParams.set('start', u.searchParams.get('t') as string);
    }
    return embedUrl.toString();
};
const VideoPlayer: TOC<{
    Element: HTMLIFrameElement;
    Args: {
        url: string;
    };
}> = template_64779b72ffe64a1894e62ff26b2ae17f(`
  <iframe
    class={{styles.player}}
    src={{parseUrl @url}}
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
    ...attributes
  ></iframe>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { VideoPlayer };
