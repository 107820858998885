import { template as template_9a03264c2adc43e4accf540a3b0f7a5c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { findGame } from '../games';
import type { Game } from '../games';
export interface GameSignature {
    Args: {
        game?: Game;
    };
}
export class GameFactory extends Component<GameSignature> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get Game() {
        return findGame(this.args.game);
    }
    static{
        template_9a03264c2adc43e4accf540a3b0f7a5c(`
    {{#if this.Game}}
      {{! @glint-ignore }}
      <this.Game />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
