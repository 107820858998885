import { template as template_6c3bcd0a063c4bb69c420f9f55ad956d } from "@ember/template-compiler";
import { isReadyForPlayback } from '../abilities';
import { SpotifyPlayerWarning } from './spotify-player-warning';
export const MaybeSpotifyPlayerWarning = template_6c3bcd0a063c4bb69c420f9f55ad956d(`
  {{#unless (isReadyForPlayback)}}
    <SpotifyPlayerWarning />
  {{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
