import { template as template_f72b22dbf6fe4da3a59f7d1e9507d2e3 } from "@ember/template-compiler";
import styles from './note.css';
import type { TOC } from '@ember/component/template-only';
interface NoteSignature {
    Element: HTMLElement;
    Args: {
        indicator?: 'info' | 'success' | 'warning' | 'error';
        importance?: 'supreme' | 'subtle' | 'plain';
    };
    Blocks: {
        default: [];
    };
}
const Note: TOC<NoteSignature> = template_f72b22dbf6fe4da3a59f7d1e9507d2e3(`
  <aside
    role="note"
    class={{styles.alert}}
    data-indicator={{if @indicator @indicator "info"}}
    data-importance={{if @importance @importance "subtle"}}
    ...attributes
  >{{yield}}</aside>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Note;
