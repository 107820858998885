// import styles from '@hokulea/core/content.css';
import { template as template_59369e57777746b493149cf4537732d1 } from "@ember/template-compiler";
import Section from './section';
import type { SectionSignature } from './section';
import type { TOC } from '@ember/component/template-only';
const CardSection: TOC<SectionSignature> = template_59369e57777746b493149cf4537732d1(`
  <Section @title={{@title}} ...attributes>
    <:header as |Headline|>
      {{#if (has-block "header")}}
        {{yield Headline to="header"}}
      {{/if}}
    </:header>

    <:body>
      {{#if (has-block "body")}}
        {{yield to="body"}}
      {{else if (has-block)}}
        {{yield}}
      {{/if}}
    </:body>
  </Section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CardSection;
