import { template as template_d80b3ffa534a48ea86a805060f1563a7 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { findAssistant } from '../assistants';
import type { Assistant } from '../assistants';
export interface AssistantSignature {
    Args: {
        assistant?: Assistant;
    };
}
export class AssistantFactory extends Component<AssistantSignature> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get Assistant() {
        return findAssistant(this.args.assistant);
    }
    static{
        template_d80b3ffa534a48ea86a805060f1563a7(`
    {{#if this.Assistant}}
      {{!@glint-ignore}}
      <this.Assistant />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
