import { template as template_a15ad118b8074d1abc69a6a9b720066e } from "@ember/template-compiler";
import styles from './features.css';
import type { TOC } from '@ember/component/template-only';
const Features: TOC<{
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}> = template_a15ad118b8074d1abc69a6a9b720066e(`
  <div class={{styles.features}} ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Features;
