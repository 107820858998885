import { template as template_cc8b9be295864418ba0193d1cfcef014 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { formatNumber, t } from 'ember-intl';
import { getCriterionKey, toIntlIntervalKey } from './-utils';
import styles from './artistic.css';
import type { JudgingSystemCriterion, JudgingSystemCriterionInterval } from '../systems/domain-objects';
import type { TOC } from '@ember/component/template-only';
export class CriterionInterval extends Component<{
    Args: {
        criterion: JudgingSystemCriterion;
        interval: JudgingSystemCriterionInterval;
    };
}> {
    get name() {
        return `artistic-${getCriterionKey(this.args.criterion).replaceAll('.', '-')}`;
    }
    static{
        template_cc8b9be295864418ba0193d1cfcef014(`
    <span data-marker={{@interval.marker}} class={{styles.interval}}>
      {{t (toIntlIntervalKey this.name @interval.marker)}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export const Score: TOC<{
    Args: {
        score?: number;
    };
}> = template_cc8b9be295864418ba0193d1cfcef014(`
  {{#if @score}}
    <span class={{styles.score}} data-score="value">{{formatNumber
        @score
        maximumFractionDigits=2
      }}</span>
  {{else}}
    -
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
