import { template as template_fa6fcb38ee47407cab914b963dcdeba1 } from "@ember/template-compiler";
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class RoutinesRoute extends Route<object> {
    static{
        template_fa6fcb38ee47407cab914b963dcdeba1(`{{outlet}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(RoutinesRoute);
