import { template as template_fd9a732dc6484028b36a79f848823100 } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class ExerciseRootRoute extends Route<object> {
    static{
        template_fd9a732dc6484028b36a79f848823100(`
    {{pageTitle "Übungen"}}

    {{outlet}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(ExerciseRootRoute);
